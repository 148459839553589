import React from "react"

import Layout from "../components/Layout"
import { AreaTable } from "../components/AreaTable"

const IndexPage = ({ location }) => (
  <Layout
    pathname={location.pathname}
    title={"Cases – UK, nations and regions"}
  >
    <h3 className="title is-3">UK</h3>
    <AreaTable areaType="overview" />
    <h3 className="title is-3">Nations</h3>
    <AreaTable areaType="nation" />
    <h3 className="title is-3">Regions</h3>
    <AreaTable areaType="region" />
  </Layout>
)

export default IndexPage
